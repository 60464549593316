import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
  } from "react";
  import Grid from "@mui/material/Grid";
  import Card from "@mui/material/Card";
  import CardContent from "@mui/material/CardContent";
  import CardActions from "@mui/material/CardActions";
  import Typography from "@mui/material/Typography";
  import { Link } from "@mui/material";
  import moment from "moment";
  import NewsService from "../services/NewsService";
  import CircularIndeterminate from "./customComponents/CircularIndeterminate";
  import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
  import utils from "../utils";
  import SearchBar from "./SearchBar";
  
  export default function NewsCards({myFilter}) {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isTabActive, setIsTabActive] = useState(true);
    const [search, setSearch] = useState("");
    const intervalRef = useRef(null);
  
    const getNews = useCallback((setLoader) => {
      if(myFilter) {
        NewsService.getAlerts(setRows,setLoader);
      } else {
        NewsService.getAllNews(setRows, setLoader);
      }
    }, []);

      useEffect(() => {
          getNews(setLoading);
      }, []);
  
    const filteredRowData = useMemo(() => {
      return rows.filter(({ attchmntText = "", symbol = "", NSURL, sm_name = "", MORE, HEADLINE, SLONGNAME }) => {
        let decodedSymbol = utils.decodeHTMLEntities(symbol);
        let bseDecodedSymbol = utils.extractCode(NSURL);
        const smallCaseSearch = search.toLowerCase();
        let filterPass =  (
          decodedSymbol?.toLowerCase().includes(smallCaseSearch) ||
          sm_name?.toLowerCase().includes(smallCaseSearch) ||
          attchmntText?.toLowerCase().includes(smallCaseSearch) ||
          MORE?.toLowerCase().includes(smallCaseSearch) || HEADLINE?.toLowerCase().includes(smallCaseSearch) ||
          SLONGNAME?.toLowerCase().includes(smallCaseSearch) || bseDecodedSymbol?.toLowerCase().includes(smallCaseSearch)
        );
        return filterPass;
      });
    }, [rows, search]);
  
    // Function to start the interval
    const startInterval = useCallback(() => {
      intervalRef.current = setInterval(() => {
        if (isTabActive) {
          getNews();
        }
      }, 10000); // 10 seconds
    }, [isTabActive, getNews]);
  
    // Function to stop the interval
    const stopInterval = useCallback(() => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }, []);
  
    // Handle visibility change
    useEffect(() => {
      const handleVisibilityChange = () => {
        setIsTabActive(document.visibilityState === "visible");
      };
  
      document.addEventListener("visibilitychange", handleVisibilityChange);
  
      // Start the interval on component mount
      startInterval();
  
      // Cleanup
      return () => {
        document.removeEventListener("visibilitychange", handleVisibilityChange);
        stopInterval();
      };
    }, [isTabActive]);
  
    return loading ? (
      <CircularIndeterminate />
    ) : (
      <>
        <SearchBar
          onInputChange={setSearch}
          onRefresh={() => getNews(setLoading)}
        ></SearchBar>
        <Grid
          container
          spacing={2}
          style={{
            marginTop: 3,
            overflowY: "scroll",
            maxHeight: "calc(100vh - 160px)",
            height: "calc(100vh - 160px)",
          }}
        >
          {filteredRowData.map((row, rowIndex) => {
            let symbol = row.dataType === 'nse' ? utils.decodeHTMLEntities(row?.symbol) : utils.extractCode(row?.NSURL);
            return (
              <Grid item xs={12} sm={6} md={4} key={rowIndex}>
                {row.dataType === 'nse' ? (
                    <Card
                    style={{
                        marginLeft: "3px",
                        marginRight: "3px",
                        height: "100%",
                        justifyContent: "space-between",
                        display: "flex",
                        flexDirection: "column"
                    }}
                    >
                    <CardContent>
                        <div style={{ marginBottom: "10px" }}>
                        <Link
                            href={`https://www.screener.in/company/${symbol}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <span>
                            <img
                                src={`https://images.5paisa.com/MarketIcons/${symbol}.png`}
                                alt="icon"
                                style={{
                                width: "25px",
                                height: "25px",
                                marginRight: "8px",
                                // border: "1px solid",
                                }}
                                onError={(e) => {
                                e.target.onerror = null; // Prevents looping
                                e.target.style.display = "none"; // Hide the broken image
                                const fallbackSpan = document.createElement("span");
                                fallbackSpan.textContent = symbol.charAt(0);
                                fallbackSpan.style.cssText = `
                                    width: 25px;
                                    height: 25px;
                                    background-color: transparent;
                                    border: 1px solid;
                                    text-align: center;
                                    line-height: 25px;
                                    font-size: 16px;
                                    color: ##0F0246;
                                    vertical-align: middle;
                                    display: inline-block;
                                    margin-right: 8px;
                                    font-Weight: bold;
                                `;
                                e.target.parentNode.appendChild(fallbackSpan); // Add the fallback content
                                }}
                            />
                            </span>
                            <Typography variant="h6">{symbol}</Typography>
                        </Link>
                        <Typography
                            color="primary"
                            variant="caption"
                            display="block"
                            gutterBottom
                        >
                            {row?.sm_name}
                        </Typography>
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                        <Typography>{row?.attchmntText}</Typography>
                        </div>
                    </CardContent>
                    <CardActions
                        style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginLeft: "7px",
                        marginRight: "7px",
                        alignItems: "end",
                        }}
                    >
                        <Typography variant="caption">
                        {moment(row?.an_dt, "DD-MMM-YYYY HH:mm:ss").format(
                            "DD-MMM-YYYY hh:mm A"
                        )}
                        </Typography>
                        <Typography variant="subtitle2">
                        <Link
                            href={row.attchmntFile}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            View Attachment
                        </Link>
                        </Typography>
                    </CardActions>
                    </Card>
                ): (
                    <Card
                style={{
                  marginLeft: "3px",
                  marginRight: "3px",
                  height: "100%",
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <CardContent>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography
                      variant="h6"
                      sx={{ textTransform: "uppercase" }}
                    >
                      <Link
                        href={`https://www.screener.in/company/${row?.SCRIP_CD}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`${symbol} (${row?.SCRIP_CD})`}
                      </Link>
                    </Typography>

                    <Typography
                      color="primary"
                      variant="caption"
                      display="block"
                      gutterBottom
                    >
                      {row?.SLONGNAME}
                    </Typography>
                    {row?.CATEGORYNAME && (
                      <Typography
                        color="#757575"
                        variant="caption"
                        display="block"
                        gutterBottom
                        sx={{
                          fontStyle: "italic",
                        }}
                      >
                        <ClassOutlinedIcon
                          style={{ marginRight: 3, fontSize: "15px" }}
                        />
                        {row?.CATEGORYNAME}
                      </Typography>
                    )}
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    <Typography>{row?.MORE || row?.HEADLINE}</Typography>
                  </div>
                </CardContent>
                <CardActions
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "7px",
                    marginRight: "7px",
                    alignItems: "end",
                  }}
                >
                  <Typography variant="caption">
                    {moment(row?.NEWS_DT).format("DD-MMM-YYYY hh:mm A")}
                  </Typography>
                  <Typography variant="subtitle2">
                    <Link
                      href={`https://www.bseindia.com/xml-data/corpfiling/AttachLive/${row?.ATTACHMENTNAME}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Attachment
                    </Link>
                  </Typography>
                </CardActions>
              </Card>
                )}
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  }
  