// src/services/NotificationService.js
import axios from "axios";
import CONFIG from "../config";

const getNews = async (setData, setLoading = () => {}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    try {
      setLoading(true);
      let {
        data: { previousDay = [], today = [] },
      } = await axios.get(`${CONFIG.API_URL}/news`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setData([...today, ...previousDay]);
    } catch (error) {
      console.error("Error getting News:", error);
    } finally {
      setLoading(false);
    }
  }
};

const getAllNews = async (setData, setLoading = () => {}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    try {
      setLoading(true);
      let {
        data: { previousDay = [], today = [] },
      } = await axios.get(`${CONFIG.API_URL}/news/all`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setData([...today, ...previousDay].sort((a, b) => new Date(b.commonDate) - new Date(a.commonDate)))
    } catch (error) {
      console.error("Error getting News:", error);
    } finally {
      setLoading(false);
    }
  }
};

const getAlerts = async (setData, setLoading = () => {}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    try {
      setLoading(true);
      let {
        data: { previousDay = [], today = [] },
      } = await axios.get(`${CONFIG.API_URL}/news/myAlerts`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setData([...today, ...previousDay].sort((a, b) => new Date(b.commonDate) - new Date(a.commonDate)))
    } catch (error) {
      console.error("Error getting News:", error);
    } finally {
      setLoading(false);
    }
  }
};


const getBseNews = async (setData, setLoading = () => {}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    try {
      setLoading(true);
      let {
        data: { previousDay = [], today = [] },
      } = await axios.get(`${CONFIG.API_URL}/bseNews`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setData([...today, ...previousDay]);
    } catch (error) {
      console.error("Error getting News:", error);
    } finally {
      setLoading(false);
    }
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getNews,
  getBseNews,
  getAllNews,
  getAlerts
};
